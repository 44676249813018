import React from "react";
import pdf from "../images/pdf.jpeg";

export default function ProjectCard(props) {
  const project = { ...props.project };
  return (
    <div className="project-card-container">
      {/* <div className="project-image">
        <img src={project.image} />
      </div> */}
      <div className="project-client">Client: {project.client}</div>
      {/* <div className="project-name">{project.name}</div> */}
      <div className="project-date">{project.date}</div>

      <div className="project-description">{project.description}</div>

      {/* <div className="project-document">
        <a href={project.document} target="_blank">
          <img src={pdf} />
        </a>
      </div> */}
    </div>
  );
}
