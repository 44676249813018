import React, { useEffect, useState } from "react";
import ProjectCard from "../components/ProjectCard";

export default function Projects() {
  const [projects, setProjects] = useState();
  useEffect(() => {
    fetch("../../data/project-data.json", {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    }).then((response) => {
      response.json().then((response) => {
        if (response.length !== 0) {
          setProjects(response);
        }
      });
    });
  }, []);
  return (
    <div className="projects-wrapper">
      <div className="projects-background-container"></div>
      <div className="project-content-wrapper">
        <div className="projects-heading">
          Here are just a few of our project examples:
        </div>
        <div className="projects-container">
          {projects &&
            projects.map((project, index) => {
              return <ProjectCard project={project} key={index} />;
            })}
        </div>
      </div>
    </div>
  );
}
